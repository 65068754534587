/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../compontents/Layout/Layout';
import MangedItFormPop from '../compontents/ManagedITFormPop/ManagedItFormPop';

type ManagedITPageProps = {
    location: any;
};

const ManagedITPage = ({ location }: ManagedITPageProps) => {
    return (
        <Layout location={location}>
            <Helmet>
                <title>Managed IT Services</title>
            </Helmet>
            <div data-testid="managed-it-container">
                {/*Intro Header*/}
                <div className="container pt-5 center">
                    <h1 className="mb-0">
                        <span style={{ fontSize: '2rem' }}>Get Your Business Running Smoothly</span>
                        <br />
                        With Managed IT Services
                    </h1>
                    <img className="img-fluid" src="../../images/managed-it/server.jpg" />
                    <p className="mainText mb-0">Experience Optimal Performance and Reduced Downtime</p>
                    <a className="button-primary" href="/contact-us">
                        Contact us to get started
                    </a>
                </div>

                {/*end Intro Header*/}

                <div className="container mt-5">
                    {/*Struggle section*/}
                    <div className="row mb-5">
                        <div className="col-md-6">
                            <h3 className="mb-0">IT Can Be Stressful</h3>
                            <p className="mainText">Don&apos;t Let It Slow You Down.</p>
                            <p>
                                Got an IT emergency? Do you need help with some new technology? Whether you&apos;re
                                scaling up and need some IT expertise, or just want to look at how to cut costs in your
                                processes, <strong>partnering with SquareHook is the answer for you</strong>.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <img className="img-fluid" src="../../images/managed-it/server-error.png" />
                        </div>
                    </div>
                    {/* endStruggle section*/}

                    {/*start of solutions*/}
                    <div className="center pt-3 mb-3">
                        <h3 className="mb-0">Turn Your Business Into A Well-oiled Machine</h3>
                        <p className="mainText mb-0">We&apos;ll Help You Get IT done!</p>
                        <img className="img-fluid" src="../../images/managed-it/squarehook-gears.jpg" />
                    </div>
                    <p>
                        You want your company running like a well-oiled machine. Let us be like your maintenance
                        workers, keeping everything working smoothly and providing you the peace of mind to focus on
                        what really matters - growing your business. Our certified technicians have the knowledge and
                        experience to handle even the most complex issues.
                    </p>
                    <h4 className="mt-5">Proactively Avoiding Downtime</h4>
                    <p>
                        While we can fix almost any <span style={{ fontWeight: 'bold' }}>techtastrophe</span>{' '}
                        <span style={{ fontStyle: 'italic' }}>(tech-catastrophe)</span> that you might run into, we can
                        help you better with a preventive approach, mitigating downtime before it happens. When you
                        partner with us, we can continuously monitor systems and networks, identifying and resolving
                        problems before they become major issues. The cost savings associated with this approach are one
                        of the top reasons to use managed services.
                    </p>

                    <div className="row my-5">
                        <div className="col-lg-7 my-auto px-5">
                            <p className="center mainText">
                                With our managed IT services, you can stop stressing about outages and downtime. We’ll
                                keep the machine well-oiled and let you get on with business.
                            </p>
                        </div>
                        <MangedItFormPop />
                    </div>

                    {/* Costs */}
                    <div className="col-lg-10 mx-auto mb-5">
                        <h3>Keeping IT Costs Down</h3>
                        <div className="row">
                            <div className="col-md-6">
                                <p>
                                    With SquareHook, you get your own dedicated, well-trained IT team at a fraction of
                                    the cost of hiring, training, and maintaining an in-house team. By letting us help
                                    with your IT infrastructure and processes, you can save money on:
                                </p>
                                <ul>
                                    <li>Maintenance for hardware and software </li>
                                    <li>Data center management</li>
                                    <li>IT staff and training</li>
                                    <li>Servers and infrastructure equipment</li>
                                    <li>Hardware purchases</li>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-6 mx-auto h-100 center">
                                <img
                                    className="img-fluid"
                                    style={{ maxHeight: '330px' }}
                                    src="../../images/managed-it/dollar-sign.jpg"
                                />
                            </div>
                        </div>
                        <p>
                            When you partner with us, we can also manage better deals cloud hosting, software, and other
                            technology-related products and services, passing on the savings to you.
                        </p>
                        <h4>Transparency</h4>
                        <p>
                            There are no hidden costs. We always keep you informed on what’s going on, which areas of
                            your IT infrastructure need improvement, data compliance worries—the whole deal. With
                            SquareHook, you get a partner in IT that knows we can only succeed together.
                        </p>
                    </div>
                    {/* end costs */}

                    {/* cloud */}

                    <h3 className="pt-5">Scalability With The Cloud</h3>
                    <div className="row pb-3">
                        <div className="col-lg-6">
                            <img className="img-fluid" src="../../images/managed-it/cloud-server.jpg" />
                        </div>
                        <div className="col-lg-6">
                            <p>
                                Managing your IT services in the cloud, you can quickly scale-up and scale-down as the
                                needs of your business change. This also lets us proactively plan for future IT needs so
                                you can stay competitive in the market.
                            </p>
                            <p>
                                <strong>Staying current</strong> with technology is one of the biggest benefits to
                                managed IT services. With new technologies constantly emerging and endless updates to be
                                made, you’ll never have to worry about your systems being outdated or making an
                                investment that doesn’t pan out.{' '}
                                <strong>Let us improve your present and pave the way for the future</strong>.
                            </p>
                            <p>
                                Learn more about{' '}
                                <a style={{ textDecoration: 'underline', fontWeight: 'bold' }} href="/devops#cloud">
                                    cloud solutions
                                </a>{' '}
                                here.
                            </p>
                        </div>
                    </div>
                    {/* end cloud */}

                    {/*end solutions section*/}
                </div>
                {/*end container*/}

                {/*CTA section*/}
                <div className="bgsection mt-5 p-5">
                    <div className="container mb-5">
                        <h2>Get IT Started</h2>
                        <p>
                            At SquareHook, we know you want to be <strong>doers, producers,</strong> the kind of people
                            who <strong>get things done</strong>. Don’t let IT costs, labor, maintenance, downtime, and
                            errors get in your way. We know that it’s way too frustrating to deal with all that when you
                            have more important things to do. Let us use our expertise to be your dedicated IT solutions
                            team.
                        </p>
                        <h4>Here's How It Works</h4>
                        <ol>
                            <li>
                                Contact us for a <strong>FREE consultation</strong>, and we’ll discuss your needs.
                            </li>
                            <li>
                                We’ll offer a <strong>FREE analysis</strong> of your current IT processes and proposal
                                showing how much you could save in time and money, and improvement on efficiency.
                            </li>
                            <li>
                                We’ll get to work and <strong>“make IT happen”!</strong> We collaborate with you every
                                step of the way to ensure your success.
                            </li>
                        </ol>
                        <div className="center mainText col-lg-8 mx-auto">
                            <p className="emphasis">
                                So contact us today for a free consultation, so you can stop stressing about IT and get
                                your business flowing smoothly.
                            </p>
                            <a className="button-primary" href="/contact-us">
                                Contact Us
                            </a>
                        </div>
                    </div>
                </div>
                {/*end CTA section*/}
            </div>
        </Layout>
    );
};

export default ManagedITPage;
