import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import ManagedITForm from '../ManagedItForm/ManagedItForm';
function ManagedItFormPop() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className="col-lg-5 bgsection p-4" style={{ border: '1px solid #0092c5' }}>
                <div className="row">
                    <div className="col-7 my-auto">
                        <h4>How can Managed IT help your business?</h4>
                        <p>
                            Get a <strong>free</strong> brief to see the ways managed IT can save you costs and increase
                            performance
                        </p>
                        <a className="button-primary" onClick={handleShow}>
                            Free Document
                        </a>
                    </div>
                    <div className="col-5">
                        <a>
                            <img
                                style={{ cursor: 'pointer' }}
                                className="img-fluid"
                                onClick={handleShow}
                                src="../images/managed-it-doc.png"
                            />
                        </a>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Get the free document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ManagedITForm />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ManagedItFormPop;
