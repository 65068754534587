import { default as React, useEffect } from 'react';
type HsbtpCreateArgumentType = {
    region: string;
    portalId: string;
    formId: string;
    target: string;
};

type HbsptType = {
    forms: {
        create: (options: HsbtpCreateArgumentType) => void;
    };
};

declare const hbspt: HbsptType;
const ManagedITForm: React.FC = () => {
    const TARGET_ID = 'hubspot-target';
    useEffect(() => {
        // First check if the script already exists on the dom
        // by searching for an id
        const id = 'hubspot-form-script';
        if (document.getElementById(id) === null) {
            const script = document.createElement('script');
            script.setAttribute('src', 'https://js.hsforms.net/forms/embed/v2.js');
            script.setAttribute('type', 'text/javascript');
            script.setAttribute('charset', 'utf-8');

            // Append it to the head

            document.head.appendChild(script);

            // now wait for it to load...
            script.onload = () => {
                hbspt.forms.create({
                    region: 'na1',
                    portalId: '22505045',
                    formId: 'dc414397-fd15-4103-8a8b-584bf6b5620f',
                    target: `#${TARGET_ID}`,
                });
            };
        }
    }, []);

    return (
        <div>
            <p>See the ways Managed IT can help your business. Sign up for the newsletter to get the document.</p>

            <div>
                <div id={TARGET_ID}></div>
            </div>
        </div>
    );
};

export default ManagedITForm;
